<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :colon="false" @submit="handleSubmit" :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="姓名">
                            {{ detail.userName }}
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="部门">
                            {{ detail.employee?.deptName }}
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="从事专业">
                            <a-input v-decorator="[
                                'professional',
                                {
                                    initialValue: detail.professional,
                                },
                            ]" placeholder="请输入" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="行政职务">
                            <a-input v-decorator="[
                                'administrativePosition',
                                {
                                    initialValue: detail.administrativePosition,
                                },
                            ]" placeholder="请输入" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="技术职务">
                            <a-input v-decorator="[
                                'technicalPosition',
                                {
                                    initialValue: detail.technicalPosition,
                                },
                            ]" placeholder="请输入" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="备注">
                            <a-input v-decorator="[
                                'remark',
                                {
                                    initialValue: detail.remark,
                                },
                            ]" placeholder="请输入" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="22" :md="22" :sm="24" :offset="2">
                        <a-col :lg="3" :md="3" :sm="24">
                            <a-form-item label="设计">
                                <a-checkbox v-model="detail.design"></a-checkbox>
                            </a-form-item>
                        </a-col>
                        <a-col :lg="3" :md="3" :sm="24">
                            <a-form-item label="校核">
                                <a-checkbox v-model="detail.check"></a-checkbox>
                            </a-form-item>
                        </a-col>
                        <a-col :lg="3" :md="3" :sm="24">
                            <a-form-item label="专业负责">
                                <a-checkbox v-model="detail.professionalResponsibility"></a-checkbox>
                            </a-form-item>
                        </a-col>
                        <a-col :lg="4" :md="4" :sm="24">
                            <a-form-item label="项目负责">
                                <a-checkbox v-model="detail.prjComp">公司管</a-checkbox>
                                <a-checkbox v-model="detail.prjDept">部门管</a-checkbox>
                            </a-form-item>
                        </a-col>
                        <a-col :lg="3" :md="3" :sm="24">
                            <a-form-item label="审核">
                                <a-checkbox v-model="detail.examine"></a-checkbox>
                            </a-form-item>
                        </a-col>
                        <a-col :lg="4" :md="4" :sm="24">
                            <a-form-item label="审定">
                                <a-checkbox v-model="detail.approvedComp">公司管</a-checkbox>
                                <a-checkbox v-model="detail.approvedDept">部门管</a-checkbox>
                            </a-form-item>
                        </a-col>
                        <a-col :lg="4" :md="4" :sm="24">
                            <a-form-item label="批准">
                                <a-checkbox v-model="detail.approvalComp">公司管</a-checkbox>
                                <a-checkbox v-model="detail.approvalDept">部门管</a-checkbox>
                            </a-form-item>
                        </a-col>
                        <a-col :lg="3" :md="3" :sm="24">
                            <a-form-item label="内审员">
                                <a-checkbox v-model="detail.internalAuditor"></a-checkbox>
                            </a-form-item>
                        </a-col>
                    </a-col>
                </a-row>
                <div class="footer center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                        <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>

<script>
import moment from "moment";
import EmployeeSelector from "@/components/employee-selector";
import organization from "@/mixins/organization";
import { mapGetters } from 'vuex'
import request from "@/api/request";
function getDetail(id) {
  return request({
    url: "/project-service/dsEmployee/detail/" + id,
  });
}
function add(data) {
    return request({
        url: "project-service/dsEmployee/update",
        method: "post",
        data
    });
}
export default {
    mixins: [organization],
    name: 'moneyEdit',
    
    components: {
        EmployeeSelector
    },

    data() {
        return {
            detail: {},
            selectedUser: {},
            form: this.$form.createForm(this),
            loading: false,
        };
    },

    mounted(){
        const { query } = this.$route;
        const { id } = query || {};
        getDetail(id).then(res => {
            this.detail = res;
        });
    },
    computed: {
        ...mapGetters("setting", ["findDataDict"]),

        typeList() {
            return this.findDataDict("ds_bond_type");
        },

    },

    methods: {
        handleSubmit(e) {
            e.preventDefault();

            this.form.validateFields((err, values) => {
                if (!err) {
                    this.loading = true;
                    const { query } = this.$route;
                    const { id } = query || {};
                    add({
                        ...values,
                        id:id,
                        design: this.detail.design,
                        check: this.detail.check,
                        professionalResponsibility: this.detail.professionalResponsibility,
                        prjComp: this.detail.prjComp,
                        prjDept: this.detail.prjDept,
                        examine: this.detail.examine,
                        approvedComp: this.detail.approvedComp,
                        approvedDept: this.detail.approvedDept,
                        approvalComp: this.detail.approvalComp,
                        approvalDept: this.detail.approvalDept,
                        internalAuditor: this.detail.internalAuditor,
                    })
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    this.$message.error("请填写必填项目");
                }
            });
        }
    }
};
</script>


<style lang="less" scoped>
.title {
    font-size: 14px;
    color: #1890ff;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-left: 8px;
}

.footer {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>